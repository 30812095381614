<template>
  <div class="article" :class="{ active: fullScreen }">
    <div class="tbl_wrap">
      <div class="tbl_option">
        <h2 class="curr_time">
          {{ curr_date }}
          <br />{{ curr_weekDay }}
          <span>{{ curr_time }}</span>
        </h2>
        <!-- <button class="btn_sub1 refresh_btn" @click="refreshData">
          재조회
        </button> -->
        <button @click="fullScreen = !fullScreen" class="btn_sub1">
          {{ fullScreen ? '닫기' : '전체화면' }}
        </button>
      </div>
      <div class="mes_tbl_wrap">
        <table class="mes_tbl">
          <colgroup>
            <col v-for="(n, index) in 5" :key="index" />
          </colgroup>
          <thead>
            <tr>
              <th colspan="5">
                원자재 재고현황<span>조회시간 : {{ str_time }}</span>
              </th>
            </tr>
            <tr>
              <th>원자재명</th>
              <th>전일재고(단위)</th>
              <th>입고수량(단위)</th>
              <th>출고수량(단위)</th>
              <th>현재고(단위)</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in filterMaterialList" :key="item.id">
              <td class="text_left">
                {{ item.name }}
                {{ item.standard.trim() == '' ? '' : ` - ` + item.standard }}
              </td>
              <td class="text_right">{{ item.beforeStock_unit }}</td>
              <td class="text_right">{{ item.inStock }}</td>
              <td class="text_right">
                {{ item.outStock }}
              </td>
              <td class="text_right">{{ item.nowStock_unit }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import fetchMixin from '@/mixins/fetch';
import {
  yyyymmdd,
  yyyy년MM월dd일HH시mm분ss초,
  yyyy년MM월dd일,
  HH시mm분,
  X요일,
} from '@/utils/func';

export default {
  mixins: [fetchMixin],
  data() {
    return {
      fullScreen: false,
      str_time: null,
      timerCount: 30,
      timerMax: 30,
      interval: null,
      timeInterval: null,
      currentTimeInterval: null,
      curr_date: yyyy년MM월dd일(new Date()),
      curr_time: HH시mm분(new Date()),
      curr_weekDay: X요일(new Date()),
    };
  },
  computed: {
    ...mapGetters({
      materials: 'getMaterial',
      materialTotalStock: 'getMaterialTotalStock',
      stockStatusList: 'getMaterialStockStatusListFromMaterialStockStatus',
      materialControlList: 'getMaterialControl',
      materialInOutType: 'getMaterialInOutType',
      stores: 'getStores',
      unitCodes: 'getUnitCodes',
      resourceTypes: 'getResourceType',
      tabIndex: 'getOpenTabIndexFromStatusMonitorPage',
    }),
    filterMaterialList() {
      let stockList = [];

      if (
        this.materialTotalStock.length > 0 &&
        this.materialInOutType.length > 0
      ) {
        // 현재 재고
        let materialTotalStockClone = this.lodash
          .clonedeep(this.materialTotalStock)
          .filter(
            x =>
              this.findInfoFromId(this.materials, x.material_id)
                .resource_type_id ==
                this.resourceTypes.find(y => y.detail == 'raw_material').id &&
              x.stock_quantity > 0,
          )
          .sort((a, b) => a.material_id - b.material_id);

        materialTotalStockClone.forEach(el => {
          let materialInfo = this.findInfoFromId(
            this.materials,
            el.material_id,
          );
          let foundLastMaterialControl = this.filterStoreLocation.filter(
            x => x.material_id == el.material_id,
          );
          const lastStoreId =
            foundLastMaterialControl.length > 0
              ? foundLastMaterialControl[0].store_id
              : null;

          let beforeStock = el.stock_quantity;
          let inStockSum = 0;
          let outStockSum = 0;

          if (this.filterStockStatusList[0].length > 0) {
            inStockSum = this.filterStockStatusList[0]
              .filter(
                x =>
                  x.material_id == el.material_id &&
                  x.material_in_out_type_id ==
                    this.materialInOutType.find(y => y.detail == 'buy in').id,
              )
              .map(x => x.quantity)
              .reduce((a, b) => this.$decimalAdd(a, b), 0);

            outStockSum = this.filterStockStatusList[0]
              .filter(
                x =>
                  x.material_id == el.material_id &&
                  (x.material_in_out_type_id ==
                    this.materialInOutType.find(y => y.detail == 'using out')
                      .id ||
                    x.material_in_out_type_id ==
                      this.materialInOutType.find(y => y.detail == 'return out')
                        .id),
              )

              .map(x => x.quantity)
              .reduce((a, b) => this.$decimalAdd(a, b), 0);

            beforeStock = this.$decimalSub(
              this.$decimalAdd(el.stock_quantity, outStockSum),
              inStockSum,
            );
          }

          stockList.push({
            id: materialInfo.id,
            name: materialInfo.name,
            standard: materialInfo.standard,
            beforeStock_unit:
              this.$makeComma(beforeStock) +
              ' (' +
              this.findInfoFromId(this.unitCodes, materialInfo.incoming_unit_id)
                .name +
              ')',
            beforeStock_box:
              materialInfo.order_unit == 0
                ? 0
                : this.$makeComma(
                    Math.floor(
                      this.$decimalDiv(beforeStock, materialInfo.order_unit),
                    ),
                  ),
            inStock:
              this.$makeComma(inStockSum) +
              ' (' +
              this.findInfoFromId(this.unitCodes, materialInfo.incoming_unit_id)
                .name +
              ')',
            outStock:
              this.$makeComma(outStockSum) +
              ' (' +
              this.findInfoFromId(this.unitCodes, materialInfo.incoming_unit_id)
                .name +
              ')',
            nowStock_unit:
              this.$makeComma(el.stock_quantity) +
              ' (' +
              this.findInfoFromId(this.unitCodes, materialInfo.incoming_unit_id)
                .name +
              ')',
            nowStock_box:
              materialInfo.order_unit == 0
                ? 0
                : this.$makeComma(
                    Math.floor(
                      this.$decimalDiv(
                        el.stock_quantity,
                        materialInfo.order_unit,
                      ),
                    ),
                  ),
            storeLocation:
              lastStoreId == null
                ? '-'
                : this.findInfoFromId(this.stores, lastStoreId).name,
          });
        });
      }
      // let arr = [];
      // if (this.fullScreen) {
      //   stockList.forEach((element, index) => {
      //     if (index % 9 == 0) {
      //       arr.push(stockList.slice(index, index + 9));
      //     }
      //   });
      // } else {
      //   stockList.forEach((element, index) => {
      //     if (index % 5 == 0) {
      //       arr.push(stockList.slice(index, index + 5));
      //     }
      //   });
      // }
      return stockList;
    },

    filterStockStatusList() {
      let stockStatusClone = this.lodash.clonedeep(this.stockStatusList);

      if (this.resourceTypes.length > 0) {
        stockStatusClone = stockStatusClone.filter(
          x =>
            this.findInfoFromId(this.materials, x.material_id)
              .resource_type_id ==
            this.resourceTypes.find(y => y.detail == 'raw_material').id,
        );

        let today = new Date();
        today = yyyymmdd(today);

        const todayList = this.lodash
          .clonedeep(stockStatusClone)
          .filter(x => x.create_time.substr(0, 10) == today);

        const beforeList = this.lodash
          .clonedeep(stockStatusClone)
          .filter(x => x.create_time.substr(0, 10) != today);

        return [todayList, beforeList];
      } else {
        return [];
      }
    },
    filterStoreLocation() {
      let storeLoctionClone = this.lodash.clonedeep(this.materialControlList);

      if (this.materialControlList.length > 0) {
        storeLoctionClone = storeLoctionClone.sort(
          (b, a) =>
            Number(
              a.create_time
                .replace(/-/gi, '')
                .replace(/:/gi, '')
                .replace(' ', ''),
            ) -
            Number(
              b.create_time
                .replace(/-/gi, '')
                .replace(/:/gi, '')
                .replace(' ', ''),
            ),
        );
      }
      return storeLoctionClone;
    },
  },
  methods: {
    // async refreshData() {
    //   await this.FETCH_NO_SPINNER('FETCH_MATERIAL_CONTROL', '재고 관리');
    //   await this.FETCH_NO_SPINNER('FETCH_MATERIAL_STATUS', '원자재 현황');
    //   await this.FETCH_NO_SPINNER('FETCH_MATERIAL_TOTAL_STOCK', '재고 현황');
    //   this.str_time = yyyy년MM월dd일HH시mm분ss초(new Date());
    //   this.curr_date = yyyy년MM월dd일(new Date());
    //   this.curr_time = HH시mm분(new Date());
    //   this.curr_weekDay = X요일(new Date());
    // },
  },
  destroyed() {
    console.log('destroyed');
    clearInterval(this.interval);
    clearInterval(this.timeInterval);
    clearInterval(this.currentTimeInterval);
  },
  async created() {
    if (this.stores.length < 1) {
      await this.FETCH('FETCH_STORE', '창고 리스트');
    }
    if (this.resourceTypes.length < 1) {
      await this.FETCH('FETCH_RESOURCE_TYPE', '자원유형');
    }

    this.timerCount = this.timerMax;
    await this.FETCH_NO_SPINNER('FETCH_MATERIAL_STATUS', '자재 현황');

    this.str_time = yyyy년MM월dd일HH시mm분ss초(new Date());

    if (this.materials.length < 1) {
      await this.FETCH('FETCH_MATERIAL_WITH_COMPANY', '원자재');
    }

    if (this.unitCodes.length < 1) {
      await this.FETCH('FETCH_UNIT', '단위');
    }

    if (this.materialInOutType.length < 1) {
      await this.FETCH('FETCH_MATERIAL_IN_OUT_TYPE', '자재 입출고 유형');
    }

    await this.FETCH('FETCH_MATERIAL_CONTROL', '재고 관리');
    await this.FETCH('FETCH_MATERIAL_STATUS', '원자재 현황');
    await this.FETCH('FETCH_MATERIAL_TOTAL_STOCK', '재고 현황');

    this.interval = setInterval(async () => {
      this.timerCount = this.timerMax;

      if (this.tabIndex == 0) {
        await this.FETCH_NO_SPINNER('FETCH_MATERIAL_STATUS', '자재 현황');
        await this.FETCH_NO_SPINNER('FETCH_MATERIAL_CONTROL', '재고 관리');
        await this.FETCH_NO_SPINNER('FETCH_MATERIAL_TOTAL_STOCK', '재고 현황');
      }
      clearInterval(this.timeInterval);
      this.timeInterval = setInterval(() => {
        this.timerCount -= 1;
      }, 1000);
    }, this.timerMax * 10000);

    this.timeInterval = setInterval(() => {
      this.timerCount -= 1;
    }, 1000);
    this.currentTimeInterval = setInterval(() => {
      this.str_time = yyyy년MM월dd일HH시mm분ss초(new Date());
      this.curr_date = yyyy년MM월dd일(new Date());
      this.curr_time = HH시mm분(new Date());
      this.curr_weekDay = X요일(new Date());
    }, 1000);
  },
};
</script>

<style lang="scss" scoped></style>
